import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { FileUploadService } from '../file-upload/file-upload.service';
import { System } from 'src/app/models/system.model';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(
    private http: HttpClient, 
    private router: Router,
    private _fileUploadService: FileUploadService
    ) { }

  search(name: string, limit: number=0, skip:number = 0){
    let url = `${ environment.apiUrl }/system/search/${name}?limit=${limit}&skip=${skip}` 
    
    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }

  getId(id: string){
    let url = `${ environment.apiUrl }/system/${id}` 

    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  getAll(limit: number=0, skip:number = 0){
    let url = `${ environment.apiUrl }/system?limit=${limit}&skip=${skip}` 

    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }

  save(system: System){
    let url = environment.apiUrl + '/system';

    if ( system._id ) {
      // Updating
      url += '/' + system._id;

      return this.http.put( url, system )
            .pipe(
            map( (resp: any) => {
              Swal.fire('Parametrización Actualizada', system.name, 'success');
              return resp.body;

            }));

    } else {
      // Creating
      return this.http.post( url, system )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Parametrización Creada', system.name, 'success');
                return resp.body;
              }));
    }
  }

  delete( user_id: string){
    let url = `${ environment.apiUrl }/system/${user_id}` 

    return this.http.delete(url)
          .pipe(
            map((resp: any) => {
                Swal.fire(
                'Eliminado!',
                'La Parametrización ha sido eliminado.',
                'success'
              )
            })
          );
  }
}
