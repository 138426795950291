import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) { }

  getDocuments(limit: number = 0, skip: number = 0) {
    const url = `${ environment.apiUrl }/document`;

    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body._documents)
    );
  }

  getGuides(file: string) {
    const url = `${ environment.apiUrl }/cart/guides/${file}`;

    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }

  create(files: any, filename: string) {
    const url = `${ environment.apiUrl }/file/guide/${filename}`;
    const options = {headers: {'Content-Type': 'application/json'}};

    return this.http.post(url, files, options)
    .pipe(
      map((resp: any) => resp)
    );
  }
}
