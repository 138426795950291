import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalUploadService {

  public type: string
  public id:string
  public img:string

  public hide: string = 'hide'
  
  public notification = new EventEmitter<any>();
  constructor() {
    
   }

   hideModal(){
    this.hide = 'hide'
    this.type= null
    this.id=null
  }

   showModal(_type: string, _id:string){
    this.hide = ''
    this.type= _type
    this.id=_id
     
   }
}
