import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact.model';
import Swal from 'sweetalert2';
import { FileUploadService } from '../file-upload/file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(public http: HttpClient, 
    public router: Router,
    public _fileUploadService: FileUploadService) { }

  search(name: string, limit: number=0, skip:number = 0){
    let url = `${ environment.apiUrl }/contact/search/${name}?limit=${limit}&skip=${skip}` 
    
    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }

  getId(id: string){
    let url = `${ environment.apiUrl }/contact/${id}` 

    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  getAll(limit: number=0, skip:number = 0){
    let url = `${ environment.apiUrl }/contact?limit=${limit}&skip=${skip}` 

    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }

  save(contact: Contact){
    let url = environment.apiUrl + '/contact';

    if ( contact._id ) {
      // Updating
      url += '/' + contact._id;

      return this.http.put( url, contact )
            .pipe(
            map( (resp: any) => {
              Swal.fire('Contacto Actualizado', contact.name, 'success');
              return resp.body;

            }));

    }else{
      // Creating
      
      return this.http.post( url, contact)
              .pipe(
              map( (resp: any) => {
                Swal.fire('Contacto Creado', contact.name, 'success');
                return resp.body;
              }));
    }
  }

  delete( user_id: string){
    let url = `${ environment.apiUrl }/contact/${user_id}` 

    return this.http.delete(url)
          .pipe(
            map((resp: any) => {
                Swal.fire(
                'Eliminado!',
                'El Contacto ha sido eliminado.',
                'success'
              )
            })
          );
  }
}

