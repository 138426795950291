import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { UserService } from '../user/user.service';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor() { }

  upload(file: File, type:string, id: string){

    return new Promise((resolve, reject) => {
      let formData = new FormData()
      let xhr = new XMLHttpRequest()
  
      formData.append('image', file, file.name)
  
      xhr.onreadystatechange = function() {
        if(xhr.readyState === 4){
          
          if(xhr.status === 200 || xhr.status === 201){
            resolve(JSON.parse(xhr.response))
          } else{
            reject(JSON.parse(xhr.response))
            
          }
        }
      }

      const token = localStorage.getItem('token') 
      let url = `${URL_SERVICIOS}/${type}/image/${id}/${type}`
      
      xhr.open('PATCH', url, true)
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      xhr.send( formData )
    })
    
  }
}
