import { Injectable } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FileUploadService } from '../file-upload/file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient, 
    private router: Router,
    private _fileUploadService: FileUploadService,
    ) { }

    search(name: string, limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/product/search/${name}?limit=${limit}&skip=${skip}` 
      
      return this.http.get(url)
      .pipe(
        map((resp: any) => resp.body)
      );
    }
   
    getId(id: string){
      let url = `${ environment.apiUrl }/product/${id}` 
  
      return this.http.get(url)
              .pipe(
                map((resp: any) => resp.body)
              );
    }
  
    getAll(limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/product?limit=${limit}&skip=${skip}` 
  
      return this.http.get(url)
      .pipe(
        map((resp: any) => resp.body)
      );
    }

    save(product: Product){
      let url = environment.apiUrl + '/product';
      
      if ( product._id ) {
        // Updating
        url += '/' + product._id;
  
        return this.http.put( url, product )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Producto Actualizado', product.name, 'success');
                return resp.body;

              }));
  
      }else {
        // Creating
        
        return this.http.post( url, product )
                .pipe(
                map( (resp: any) => {
                  Swal.fire('Producto Creado', product.name, 'success');
                  return resp.body;
                }));
      }
    }

    delete( user_id: string){
      let url = `${ environment.apiUrl }/product/${user_id}` 
  
      return this.http.delete(url)
            .pipe(
              map((resp: any) => {
                  Swal.fire(
                  'Eliminado!',
                  'El Producto ha sido eliminado.',
                  'success'
                )
              })
            );
    }
}
