import { Pipe, PipeTransform } from '@angular/core';
import { URL_SERVICIOS } from '../config/config'

@Pipe({
  name: 'image',
  pure: false
})
export class ImagePipe implements PipeTransform {

  transform(img: string,type: any): any {


    if(localStorage.getItem('token')){
      var token = localStorage.getItem('token');
    }

    if( !img ){
      // return `${URL_SERVICIOS}/image/${type}/notfound?token=${token}`
      return "https://rangermp.s3.amazonaws.com/no-img.jpg"
    }

    if( img.indexOf('https') >= 0){
      return img
    }
    
    return img
    
  }

}
