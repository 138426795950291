import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { User } from '@app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  user: User
  
  constructor(
    private http: HttpClient, 
    private router: Router) {}

  getAll(){
    let url = `${ environment.apiUrl }/message` 

    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  getBySeller(id: string){
    let url = `${ environment.apiUrl }/message/seller/${id}` 

    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }
}
