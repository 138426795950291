import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-data-user',
  templateUrl: './data-user.component.html',
  styles: []
})
export class DataUserComponent implements OnInit {

  @Input() customer: any
  @Input() name: string
  @Input() nit: string
  @Input() region: string
  @Input() discount_customer: string
  @Input() quota_total: number
  @Input() quota_busy: number
  @Input() quota_available: number
  constructor() { }

  ngOnInit() {
  }

}
