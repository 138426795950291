import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FileUploadService } from '../file-upload/file-upload.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { Seller } from 'src/app/models/seller.model';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(
    private http: HttpClient, 
    private router: Router,
    private _fileUploadService: FileUploadService,
  ) { }


    search(name: string, limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/seller/search/${name}/?limit=${limit}&skip=${skip}` 
      
      return this.http.get(url);
    }
  
    getId(id: string){
      let url = `${ environment.apiUrl }/seller/${id}` 
  
      return this.http.get(url)
      .pipe(
        map((resp: any) => resp.body)
      );
    }
  
    getAll(limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/seller/?limit=${limit}&skip=${skip}` 
  
      return this.http.get(url)
      .pipe(
        map((resp: any) => resp.body)
      );
    }

    saveSeller(seller: Seller){
      let url = environment.apiUrl + '/seller';

      if ( seller._id ) {
        // Updating
        url += '/' + seller._id;
  
        return this.http.put( url, seller )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Vendedor Actualizado', seller.name, 'success');
                return resp.body;

              }));
  
      }else {
        // Creating
        return this.http.post( url, seller )
                .pipe(
                map( (resp: any) => {
                  Swal.fire('Vendedor Creado', seller.name, 'success');
                  return resp.body;
                }));
      }
    }

    deleteSeller(token: string,  user_id: string){
      let url = `${ environment.apiUrl }/seller/${user_id}` 
  
      return this.http.delete(url)
            .pipe(
              map((resp: any) => {
                  Swal.fire(
                  'Eliminado!',
                  'El Vendedor ha sido eliminado.',
                  'success'
                )
              })
            );
    }
}
