import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { throwError } from 'rxjs/internal/observable/throwError';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient,
              private router: Router) { }


  getId(id: string) {
    const url = `${ environment.apiUrl }/order/${id}`;
    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  getAll(seller: string, limit: number= 0, skip: number = 0) {
    const url = `${ environment.apiUrl }/order?seller=${seller}&limit=${limit}&skip=${skip}`;
    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  search(seller: string, term: string, limit: number= 0, skip: number = 0) {
    const url = `${ environment.apiUrl }/order?seller=${seller}&limit=${limit}&skip=${skip}&search=${term}`;
    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  // tslint:disable-next-line: variable-name
  getCustomer(code_sap: string) {
    const url = `${ environment.apiUrl }/order/customer/${code_sap}`;
    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  getProducts() {
    const url = `${ environment.apiUrl }/order/products`;
    return this.http.get(url)
    .pipe(
      map((resp: any) => resp),
      catchError((err: HttpErrorResponse) => {
        Swal.fire('Error', err.error, 'error');
        return throwError(err.error.message);
      })
    );
  }

  // tslint:disable-next-line: variable-name
  getInfoCustomer(code_sap: string) {
    const url = `${ environment.apiUrl }/sap/customer?kunnr='${code_sap}'&vkorg='1100'&kkber='F001'`;

    return this.http.get(url)
    .pipe(
      map((resp: any) => resp),
      catchError((err: HttpErrorResponse) => {
        Swal.fire('Error', err.error, 'error' );
        return throwError(err.error.message);
      })
    );
  }

  loadInfoWallet(username: string, bukrs: string) {

    const url = `${ environment.apiUrl }/sap/wallet?kunnr='${username}'&bukrs='${bukrs}'`;

    return this.http.get(url)
    .pipe(
      map((resp: any) => resp),
      catchError((err: HttpErrorResponse) => {
        Swal.fire('Error', err.error, 'error' );
        return throwError(err.error.message);
      })
    );
  }

  loadInvoices(username: string) {

    const url = `${ environment.apiUrl }/sap/invoices?kunag='${username}'`;

    return this.http.get(url)
    .pipe(
      map((resp: any) => resp),
      catchError((err: HttpErrorResponse) => {
        Swal.fire('Error', err.error, 'error' );
        return throwError(err.error.message);
      })
    );
  }

  getConditions(kunnr: string, kschl: string, matnr: string) {
    const url = `${ environment.apiUrl }/sap/conditions?kunnr=${kunnr}&matnr=${matnr}&kschl=${kschl}`;
    return this.http.get(url)
    .pipe(
      map((resp: any) => resp),
      catchError((err: HttpErrorResponse) => {
        Swal.fire('Error', err.error, 'error' );
        return throwError(err.error.message);
      })
    );
  }

  // getInfoInventory(matnr:string){
  //   let url = `${ environment.apiUrl }/sap/inventory?matnr='${matnr}'&werks='7000'&lgort='7010'`
  //   return this.http.get(url)
  //   .pipe(
  //     map((resp: any) => resp),
  //     catchError((err: HttpErrorResponse) => {
  //       Swal.fire('Error', err.error, 'error' )
  //       return throwError(err.error.message)
  //     })
  //   )
  // }

  async getInfoInventoryAsync(matnr: string) {
    const url = `${ environment.apiUrl }/sap/inventory?matnr='${matnr}'&werks='7000'&lgort='7010'`;
    const data = await this.http.get(url).toPromise();
    return data;
  }

  async getConditionsAsync(kunnr: string, kschl: string, matnr: string) {
    const url = `${ environment.apiUrl }/sap/conditions?kunnr=${kunnr}&matnr=${matnr}&kschl=${kschl}`;
    const data = await this.http.get(url).toPromise();
    return data;
  }
}
