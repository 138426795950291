import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate  {

  constructor(
    public _usuarioService: UserService,
              public router: Router){

  }
  
  canActivate(){
    if(this._usuarioService.logged()){
      return true
    } else {
      this.router.navigate(['/login']);
      return false
    }
  }
}
