import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { SidebarService, AuthenticationService, UserService } from 'src/app/services/service.index';
import { Router } from '@angular/router';
import { element } from 'protractor';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  user: User;
  menus: any = []
  array: any

  constructor( 
    public _sidebar: SidebarService,
    private router: Router,
    public _userService: UserService
  ) {
    this.user = this._userService.user;
    // console.log(this.user.role)
    this.array = _sidebar.menu
    for(let i =0; i<this.array.length; i++) {
      const role = this.array[i].show.filter(element => {
        return element == this.user.role
      })
      if(role.length >0){
        this.menus.push(this.array[i])
      }
    }
    // console.warn(this.menus)
   }

  ngOnInit() {
    
  }

  logout() {
    this._userService.logout();
}

}
