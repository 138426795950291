import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private http: HttpClient, 
    private router: Router,
  ) { }


  getId(id: string){
    let url = `${ environment.apiUrl }/region/${id}` 

    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  getAll(limit: number=0, skip:number = 0){
    let url = `${ environment.apiUrl }/region?limit=${limit}&skip=${skip}` 
    
    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }
}
