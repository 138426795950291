import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Category } from 'src/app/models/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient, 
    private router: Router) { }

    search(name: string, limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/category/search/${name}?limit=${limit}&skip=${skip}` 
      
      return this.http.get(url);
    }

    searchCategoryBySection(id: string, limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/category/${id}/section`;
      
      return this.http.get(url);
    }
  
    getId(id: string){
      let url = `${ environment.apiUrl }/category/${id}` 
  
      return this.http.get(url)
              .pipe(
                map((resp: any) => resp.body)
              );
    }
  
    getAll(limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/category?limit=${limit}&skip=${skip}` 
  
      return this.http.get(url)
              .pipe(
                map((resp: any) => resp.body)
              );
    }

    save(category: Category){
      let url = environment.apiUrl + '/category';

      if ( category._id ) {
        // Updating
        url += '/' + category._id;
  
        return this.http.put( url, category )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Categoria Actualizada', category.name, 'success');
                return resp.body;

              }));
  
      }else {
        // Creating
        return this.http.post( url, category )
                .pipe(
                map( (resp: any) => {
                  Swal.fire('Categoria Creada', category.name, 'success');
                  return resp.body;
                }));
      }
    }

    delete( user_id: string){
      let url = `${ environment.apiUrl }/category/${user_id}` 
  
      return this.http.delete(url)
            .pipe(
              map((resp: any) => {
                  Swal.fire(
                  'Eliminado!',
                  'La Categoria ha sido eliminada.',
                  'success'
                )
              })
            );
    }
}
