import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { PagesComponent } from './pages/pages.component';
import { LoginGuard } from './services/service.index';


const routes: Routes = [
 { path: 'login', component: LoginComponent },
 { path: 'forgot-password', component: ForgotPasswordComponent },
 {
    path: '',
    component: PagesComponent,
    canActivate: [ LoginGuard ],
    loadChildren: './pages/pages.module#PagesModule'
  },
 { path: '**', component: PageNotFoundComponent}
];

export const APP_ROUTES = RouterModule.forRoot( routes, { useHash:true })