import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
 
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Section } from 'src/app/models/section.model';
import { FileUploadService } from '../file-upload/file-upload.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  constructor(
    private http: HttpClient, 
    private router: Router,
    public _fileUploadService: FileUploadService) { }

    search(name: string, limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/section/search/${name}?limit=${limit}&skip=${skip}` 
      
      return this.http.get(url);
    }

    searchSectionByCatalog(id: string, limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/section/${id}/catalog` 
      console.log(url)
      
      return this.http.get(url)
      .pipe(
        map((resp: any) => resp)
      );
    }
  
    getId(id: string){
      let url = `${ environment.apiUrl }/section/${id}` 
  
      return this.http.get(url)
              .pipe(
                map((resp: any) => resp.body)
              );
    }
  
    getAll(limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/section?limit=${limit}&skip=${skip}` 
      
      return this.http.get(url)
              .pipe(
                map((resp: any) => resp.body)
              );
    }

    save(section: Section){
      let url = environment.apiUrl + '/section';

      if ( section._id ) {
        // Updating
        url += '/' + section._id;
  
        return this.http.put( url, section )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Sección Actualizada', section.name, 'success');
                return resp.body;

              }));
  
      }else {
        // Creating
        return this.http.post( url, section )
                .pipe(
                map( (resp: any) => {
                  Swal.fire('Sección Creada', section.name, 'success');
                  return resp.section;
                }));
      }
    }

    delete( user_id: string){
      let url = `${ environment.apiUrl }/section/${user_id}` 
  
      return this.http.delete(url)
            .pipe(
              map((resp: any) => {
                  Swal.fire(
                  'Eliminado!',
                  'La Sección ha sido eliminada.',
                  'success'
                )
              })
            );
    }
}
