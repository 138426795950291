import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Subcategory } from '@app/models/subcategory.model';

@Injectable({
  providedIn: 'root'
})
export class SubcategoryService {

  constructor(
    private http: HttpClient, 
    private router: Router) { }

    search(name: string, limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/subcategory/search/${name}?limit=${limit}&skip=${skip}` 
      
      return this.http.get(url);
    }

    searchSubcategoryByCategory(id: string, limit: number=0, skip: number = 0){
      const url = `${ environment.apiUrl }/subcategory/${id}/category`;
      return this.http.get(url);
    }
  
    getId(id: string){
      let url = `${ environment.apiUrl }/subcategory/${id}` 
  
      return this.http.get(url)
              .pipe(
                map((resp: any) => resp.body)
              );
    }
  
    getAll(limit: number=0, skip:number = 0){
      let url = `${ environment.apiUrl }/subcategory?limit=${limit}&skip=${skip}` 
  
      return this.http.get(url)
              .pipe(
                map((resp: any) => resp.body)
              );
    }

    save(subcategory: Subcategory){
      let url = environment.apiUrl + '/subcategory';

      if ( subcategory._id ) {
        // Updating
        url += '/' + subcategory._id;
  
        return this.http.put( url, subcategory )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Subcategoria Actualizada', subcategory.name, 'success');
                return resp.body;

              }));
  
      }else {
        // Creating
        return this.http.post( url, subcategory )
                .pipe(
                map( (resp: any) => {
                  Swal.fire('Subcategoria Creada', subcategory.name, 'success');
                  return resp.body;
                }));
      }
    }

    delete( user_id: string){
      let url = `${ environment.apiUrl }/subcategory/${user_id}` 
  
      return this.http.delete(url)
            .pipe(
              map((resp: any) => {
                  Swal.fire(
                  'Eliminado!',
                  'La Subcategoria ha sido eliminada.',
                  'success'
                )
              })
            );
    }
}
