import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../services/_auth/authentication.service';
import { UserService } from '@app/services/service.index';
import { User } from '@app/models/user.model';

declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string;
  rememberme = false;
  returnUrl: string;
  loading = false;
  error = '';
  year: number = new Date().getFullYear();

  constructor(
    private route: ActivatedRoute,
    private router: Router,

    public usuarioService: UserService) {}

  ngOnInit() {
    init_plugins();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    this.email = localStorage.getItem('email') || '';
    if (this.email.length > 0) {
      this.rememberme = true;
    }
  }

  login(form: NgForm) {

    if (form.invalid) {
      return;
    }

    const usuario = new User(null, form.value.email, form.value.email, form.value.password );
    this.usuarioService.login( usuario, this.rememberme )
    .subscribe( data => {
      if (data == true) {
        this.router.navigate(['/dashboard']);
      }
    });
  }
}
