import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component'
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component'
// import { Footer1Component } from './footer1/footer1.component'

// Pipes
import { PipesModule } from '../pipes/pipes.module';

import { SidebarComponent } from './sidebar/sidebar.component';
import { DataUserComponent } from './data-user/data-user.component';
import { ModalUploadComponent } from '@app/components/modal-upload/modal-upload.component';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    PipesModule
  ],
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    ModalUploadComponent,
    SidebarComponent,
    DataUserComponent,
  ],
  exports: [
    HeaderComponent,
    BreadcrumbsComponent,
    DataUserComponent,
    SidebarComponent,
    ModalUploadComponent
  ]
})

export class SharedModule { }