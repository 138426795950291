import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { 
    // SharedService, MenuService, ServiceService, 
    UserService, AuthGuard, FileUploadService, 
    CatalogService, SectionService, CategoryService,
    SellerService, ContactService, SystemService,
    ProductService, SubcategoryService
        } from './service.index';
import { HttpClientModule } from '@angular/common/http';
import { ModalUploadService } from '../components/modal-upload/modal-upload.service';
import { LoginGuard } from './guard/login-guard.guard';
import { MessagesService } from './pages/messages.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    // SharedService,
    MessagesService,
    UserService,
    CatalogService,
    SectionService,
    CategoryService,
    SubcategoryService,
    SellerService,
    ContactService,
    SystemService,
    FileUploadService,
    AuthGuard,
    ModalUploadService,
    ProductService,
    LoginGuard
  ],
  bootstrap: []
})
export class ServiceModule { }
