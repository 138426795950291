import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { UserService } from '@app/services/service.index';
import { OrderService } from '@app/services/pages/order.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: User;

  constructor(
    public _usuarioService: UserService,
    private _orderService: OrderService,
    public router: Router
  ) { 
    this.user = this._usuarioService.user;
    if (this.user == null){
      router.navigate(['login'])
    } 

    this.loadProducts()
  }

  ngOnInit() {
    this.user = this._usuarioService.user;
  }

  loadProducts() {
    this._orderService.getProducts()
    .subscribe( data => {
      localStorage.setItem('products', JSON.stringify(data.body._products) );
    });
  }

  // constructor(
  //   private router: Router,
  //   private authenticationService: AuthenticationService) {
  //   this.authenticationService.currentUser.subscribe(x => this.user = x);
  //  }

  // ngOnInit() {
  // }

  logout() {
    this._usuarioService.logout();
}

}
