import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment';
import { Destination } from '../../models/destination.model'
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DestinationService {

  constructor(
    private http: HttpClient, 
    private router: Router) { }

  searchDestination(id: string, name: string, limit: number=0, skip:number = 0){
    
    let url = `${ environment.apiUrl }/destination/search/${id}/${name}?limit=${limit}&skip=${skip}` 
    
    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }
   
  getId(id: string){
    let url = `${ environment.apiUrl }/destination/${id}` 

    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }
  
  getAll(id: string, limit: number=0, skip:number = 0){
    let url = `${ environment.apiUrl }/destination/customer/${id}?limit=${limit}&skip=${skip}` 
    
    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }

  save(destination: Destination){
    let url = environment.apiUrl + '/destination';
    if ( destination._id ) {
      // Updating
      url += '/' + destination._id;
      return this.http.put( url, destination )
            .pipe(
            map( (resp: any) => {
              Swal.fire('Destinatario Actualizado', destination.name1, 'success');
              return resp.body;
            }));
    } else {
      // Creating
      return this.http.post( url, destination )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Destinatario Creado', destination.name1, 'success');
                return resp.body;
              }));
    }
  }

  delete( user_id: string, token: string){
    let url = `${ environment.apiUrl }/destination/${user_id}` 

    return this.http.delete(url)
          .pipe(
            map((resp: any) => {
                Swal.fire(
                'Eliminado!',
                'El Destinatario ha sido eliminado.',
                'success'
              )
            })
          );
  }
}
