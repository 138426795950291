import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http'
import { Customer } from '../../models/customer.model'
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FileUploadService } from '../file-upload/file-upload.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient, 
    private router: Router,
    private _fileUploadService: FileUploadService,
    ) { }

    search(name: string, limit: number=0, skip:number = 0){
      const url = `${environment.apiUrl}/customer/search/${name}?limit=${limit}&skip=${skip}`

      return this.http.get(url)
      .pipe(
        map((resp: any) => resp)
      );
    }
   
    getId(id: string){
      const url =  `${environment.apiUrl}/customer/${id}`
      
      return this.http.get(url)
      .pipe(
        map((resp: any) => resp.body)
      );
    }
  
    getAll(limit: number=0, skip:number = 0){
      const url =  `${environment.apiUrl}/customer?imit=${limit}&skip=${skip}`
      
      // return this.http.get<Customer[]>(`${environment.apiUrl}/customer?imit=${limit}&skip=${skip}`);
      return this.http.get(url)
      .pipe(
        map((resp: any) => resp)
      );
    }

    save(customer: any){
      let url = environment.apiUrl + '/customer';

      if ( customer._id ) {
        // Updating
        url += '/' + customer._id;
  
        return this.http.put( url, customer )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Cliente Actualizado', customer.name1, 'success');
                return resp.body;

              }));
  
      }else {
        // Creating
        console.log('pee', customer)
        return this.http.post( url, customer )
                .pipe(
                map( (resp: any) => {
                  Swal.fire('Cliente Creado', customer.name1, 'success');
                  return resp.body;
                }));
      }
    }
    savePoints(customer: any, restpoints: any){
      let url = environment.apiUrl + '/customer/upoints';

    
        // Updating
        url += '/' + customer._id;
  
        return this.http.put( url, restpoints )
              .pipe(
              map( (resp: any) => {
                return resp.body;
              }));
  
    }
    delete( user_id: string){
      let url = `${ environment.apiUrl }/customer/${user_id}` 
  
      return this.http.delete(url)
            .pipe(
              map((resp: any) => {
                  Swal.fire(
                  'Eliminado!',
                  'El Cliente ha sido eliminado.',
                  'success'
                )
              })
            );
    }
}
