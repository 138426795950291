export { FileUploadService } from './file-upload/file-upload.service';
export { AuthGuard } from './_helpers/auth.guard'

export { LoginGuard } from './guard/login-guard.guard';

export { AuthenticationService } from './_auth/authentication.service';
export { SidebarService } from './shared/sidebar.service';
// export { SharedService } from './shared/shared.service';

//Services Pages
export { UserService } from './user/user.service';
export { CatalogService } from './pages/catalog.service';
export { SectionService } from './pages/section.service';
export { CategoryService } from './pages/category.service';
export { SubcategoryService } from './pages/subcategory.service';
export { BrandService } from './pages/brand.service';
export { SellerService } from './pages/seller.service';
export { ContactService } from './pages/contact.service';
export { SystemService } from './pages/system.service'
export { ProductService } from './pages/product.service';
export { CustomerService } from './pages/customer.service';
export { RegionService } from './pages/region.service';
export { DestinationService } from './pages/destination.service';
export { FilesService } from './pages/files.service';
export { MessagesService } from './pages/messages.service';