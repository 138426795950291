import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SidebarService {

  menu: any = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'mdi mdi-gauge',
      show: ['ADMIN_ROLE', 'SELLER_ROLE', 'SERVICE_ROLE'],
      submenu: [
        {
          title: 'Dashboard',
          url: '/dashboard',
          icon: ''
        },
      ]
    },
    {
      title: 'Pedidos',
      icon: 'mdi mdi-tablet-ipad',
      show: ['ADMIN_ROLE', 'SELLER_ROLE', 'SERVICE_ROLE'],
      submenu: [
        {
          title: 'Crear Pedido',
          url: '/client',
          icon: ''
        },
        {
          title: 'Ver Historial',
          url: '/orders',
          icon: 'mdi mdi-view-headline'
        },
      ]
    },
    {
      title: 'Clientes',
      icon: 'mdi mdi-account-multiple',
      show: ['ADMIN_ROLE', 'SERVICE_ROLE'],
      submenu: [
        {
          title: 'Listado',
          url: '/customer',
          icon: 'people'
        },
      ]
    },
    {
      title: 'Configuración',
      icon: 'mdi mdi-settings',
      show: ['ADMIN_ROLE'],
      submenu: [
        {
          title: 'Marca',
          url: '/brand',
          icon: 'dvr'
        },
        {
          title: 'Catalogo',
          url: '/catalog',
          icon: 'dvr'
        },
        {
          title: 'Sección',
          url: '/section',
          icon: 'dvr'
        },
        {
          title: 'Categoria',
          url: '/category',
          icon: 'category'
        },
        {
          title: 'SubCategoria',
          url: '/subcategory',
          icon: 'subcategory'
        },
        {
          title: 'Producto',
          url: '/product',
          icon: 'category'
        },
      ]
    },
    {
      title: 'E-commerce',
      icon: 'mdi mdi-web',
      show: ['ADMIN_ROLE', 'SERVICE_ROLE'],
      submenu: [
        {
          title: 'Vendedores',
          url: '/seller',
          icon: 'dashboard'
        },
        {
          title: 'Directorio',
          url: '/contact',
          icon: 'dashboard'
        },
        {
          title: 'Sistema',
          url: '/parametrization',
          icon: 'dashboard'
        },
        {
          title: 'Archivos',
          url: '/files',
          icon: 'dashboard'
        },
      ]
    },
    {
      title: 'Documentos',
      icon: 'mdi mdi-file-pdf',
      show: ['ADMIN_ROLE', 'SELLER_ROLE', 'SERVICE_ROLE'],
      submenu: [
        {
          title: 'PDF',
          url: '/documents',
          icon: 'people'
        },
      ]
    },
    {
      title: 'Acceso',
      icon: 'mdi mdi-account-settings-variant',
      show: ['ADMIN_ROLE', 'SERVICE_ROLE'],
      submenu: [
        {
          title: 'Usuarios',
          url: '/user',
          icon: 'people'
        },
      ]
    },
  ];

  constructor() { }
}
