import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FileUploadService } from '../file-upload/file-upload.service';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User;
  token: string;

  constructor(private http: HttpClient,
              private router: Router,
              private _fileUploadService: FileUploadService) {
    this.loadStorage();
  }

  logged() {
    if (this.token) {
      return ( this.token.length > 5) ?  true : false;
    }
    return false;
  }

  loadStorage() {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user')) ;
      this.token = localStorage.getItem('token') ;
    } else {
      this.user = null;
      this.token = '';
    }
  }

  saveStorage( id: string, token: string, user: User ) {
    localStorage.setItem('token', token );
    localStorage.setItem('user', JSON.stringify(user) );

    this.user = user;
    this.token = token;
  }

  login(user: User, rememberme: boolean = false) {
    if (rememberme) {
      localStorage.setItem('email', user.email);
    } else {
      localStorage.removeItem('email');
    }

    const url = `${ environment.apiUrl }/login`;

    return this.http.post(url, user).pipe(
      map((resp: any) => {
        if (resp.body.user.role == 'USER_ROLE') {
          Swal.fire('Login error', 'Usuario no valido', 'error' )
          return throwError('Usuario no valido')
        } else {
          this.saveStorage(resp.body.user._id, resp.body.token, resp.body.user)
          return true;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if ( err.status === 0 ) {
          Swal.fire('Login error', 'Lost Connection.  Try again!!', 'error' );
          return throwError('Lost Connection.  Try again!!');
        } else {
          Swal.fire('Login error', err.error.message, 'error');
          return throwError(err.error.message);
        }
      })
    );
  }

  logout() {
    this.user = null;
    this.token = '';

    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('ranking');
    localStorage.removeItem('userSAP');
    localStorage.removeItem('search_order');
    localStorage.removeItem('products');

    this.router.navigate(['/login']);
  }

  searchUser(name: string, limit: number= 0, skip: number = 0) {
    const url = `${ environment.apiUrl }/user/search/${name}?limit=${limit}&skip=${skip}`;
    return this.http.get(url);
  }

  getId(id: string, limit: number= 0, skip: number = 0) {
    const url = `${ environment.apiUrl }/user/${id}`;

    return this.http.get(url);
  }

  getAll(limit: number= 0, skip: number = 0) {
    const url = `${ environment.apiUrl }/user?limit=${limit}&skip=${skip}`;

    return this.http.get(url);
  }

  save(user: User) {
    let url = environment.apiUrl + '/user';

    if ( user._id ) {
      // Updating
      url += '/' + user._id;

      return this.http.put( url, user )
            .pipe(
            map( (resp: any) => {
              Swal.fire('Usuario Actualizado', user.name, 'success');
              return resp.body;

            }));

    } else {
      // Creating
      return this.http.post( url, user )
              .pipe(
              map( (resp: any) => {
                Swal.fire('Usuario Creado', user.name, 'success');
                return resp.body;
              }));
    }
  }

  delete( user_id: string) {
    const url = `${ environment.apiUrl }/user/${user_id}`;

    return this.http.delete(url)
          .pipe(
            map((resp: any) => {
                Swal.fire(
                'Eliminado!',
                'El Usuario ha sido eliminado.',
                'success'
              );
            })
          );
  }

  updateUser( user: User) {
    const url = `${ environment.apiUrl }/user/${user._id}`;

    return this.http.put(url, user)
          .pipe(
            map((resp: any) => {

              // this.saveStorage( resp.user._id, this.token, resp.user)

              Swal.fire(
              'Actualizado!',
              'El Usuario ha sido actualizado.',
              'success'
              );

              return true;
            })
          );
  }

  updatePassword( user: any) {
    const url = `${ environment.apiUrl }/user/password/${user._id}`;

    return this.http.put(url, user)
          .pipe(
            map((resp: any) => {

              // this.saveStorage( resp.user._id, this.token, resp.user)

              Swal.fire(
              'Actualizado!',
              'El password ha sido actualizado.',
              'success'
              );
              return true;
            })
          );
  }

  uploadImage(file: File, id: string) {
    this._fileUploadService.upload(file, 'user', id)
        .then((resp: any) => {
          this.user.image = resp.user.image;
          Swal.fire(
              'Imagen Actualizada!',
              `Se actualizo la imagen de ${this.user.name}`,
              'success'
              );
          return true;
        })
        .catch(resp => {
          console.log(resp);
        });
  }
}
