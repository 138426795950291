export class User{
    constructor(
        public name: string,
        public username: string,
        public email: string,
        public password: string,
        public phone?: string,
        public role?: string,
        public _id?: string,
        public image?: string,
        public status?: string,
        public google?: boolean,
        public created_at?: Date,
        public token?: string
    ) {}
}