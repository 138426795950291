import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../../models/user.model';
import { throwError } from 'rxjs/internal/observable/throwError';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private currenTokenSubject: BehaviorSubject<string>;
  public currentToken: Observable<string>;

  constructor(private http: HttpClient) {
    // Token
    this.currenTokenSubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('currentToken')));
    this.currentToken = this.currenTokenSubject.asObservable();

    // Urer
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currenTokenValue(): String {
    return this.currenTokenSubject.value;
  }

login(username: string, password: string, rememberme: boolean) {
  if(rememberme){
    localStorage.setItem('email', username);
  }else{
    localStorage.removeItem('email');
  }

  return this.http.post<any>(`${environment.apiUrl}/login`, { username, password })
      .pipe(map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user.body.user));
          localStorage.setItem('currentToken', JSON.stringify(user.body.token));
          this.currentUserSubject.next(user);
          return user;
      }),
      catchError((err: HttpErrorResponse) => {
        Swal.fire('Login error', 'Login failed wrong credentials!!', 'error' )
        return throwError(err)
      }));
}

logout() {
    // remove user from local storage to log token and user out
    localStorage.removeItem('curruserentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('userSAP');
    localStorage.removeItem('ranking');
    localStorage.removeItem('id');
    localStorage.removeItem('products');
    
    this.currentUserSubject.next(null);
}
}
