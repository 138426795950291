import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Brand } from 'src/app/models/brand.model';
import Swal from 'sweetalert2';
import { FileUploadService } from '../file-upload/file-upload.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(public http: HttpClient, 
    public router: Router,
    public _fileUploadService: FileUploadService
    ) { }

  search(name: string, limit: number=0, skip:number = 0){
    let url = `${ environment.apiUrl }/brand/search/${name}?limit=${limit}&skip=${skip}` 
    
    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }

  getId(id: string, token: string){
    let url = `${ environment.apiUrl }/brand/${id}?` 

    return this.http.get(url)
            .pipe(
              map((resp: any) => resp.body)
            );
  }

  getAll(limit: number=0, skip:number = 0){
    let url = `${environment.apiUrl}/brand?limit=${limit}&skip=${skip}` 

    return this.http.get(url)
    .pipe(
      map((resp: any) => resp.body)
    );
  }

  save(brand: Brand, token: string){
    let url = environment.apiUrl + '/brand';

    if ( brand._id ) {
      // Updating
      url += '/' + brand._id;
      return this.http.put( url, brand )
            .pipe(
            map( (resp: any) => {
              Swal.fire('Marca Actualizada', brand.name, 'success');
              return resp;

            }));

    }else{
      // Creating
      
      return this.http.post( url, brand)
              .pipe(
              map( (resp: any) => {
                Swal.fire('Marca Creada', brand.name, 'success');
                return resp;
              }));
    }
  }

  delete( user_id: string){
    let url = `${ environment.apiUrl }/brand/${user_id}?` 

    return this.http.delete(url)
          .pipe(
            map((resp: any) => {
                Swal.fire(
                'Eliminado!',
                'La Marca ha sido eliminada.',
                'success'
              )
            })
          );
  }
}
