import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private _usuarioService: UserService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentToken = this._usuarioService.token

    if (currentToken) {
      request = request.clone({
        setHeaders: {
            Authorization: `access-token ${currentToken}`
        }
      })
    }

    return next.handle(request).pipe( tap(() => {},
    (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 500 || err.status === 0) {
          this.router.navigate(['login']);
        }
        return throwError(err.statusText);
      }
    }));
  }
}
