import { Component, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/services/service.index';
import { ModalUploadService } from './modal-upload.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.css']
})
export class ModalUploadComponent implements OnInit {

  uploadImage: File
  imageTemp: string
  imageActual: string

  constructor(
    public _fileUploadService:FileUploadService,
    public _modalUploadService: ModalUploadService
  ) { 
  }

  ngOnInit() {
  }

  closeModal(): void {
    this.uploadImage = null 
    this.imageTemp = null
    this._modalUploadService.hideModal()
  }

  selectImage(file: File){
    if (!file){
      this.uploadImage = null
      return
    }

    if(file.type.indexOf('image')<0){
      Swal.fire('Error al cargar la imagen', 'El archivo seleccionado no es imagen', 'error');
      this.uploadImage = null
      return
    }

    this.uploadImage = file
    let reader = new FileReader()
    let urlImageTemp = reader.readAsDataURL(file)

    reader.onloadend = () => this.imageTemp =  reader.result as string
    
  }

  changeImage(){
    this._fileUploadService.upload(this.uploadImage, this._modalUploadService.type, this._modalUploadService.id)
    .then((resp:any)=>{
      
      this._modalUploadService.notification.emit(resp)
      this.closeModal()

      return true;
      
    })
    .catch(resp=>{
      console.log(resp);
      
    })
  }

}
